// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
	$prev-key: null;
	$prev-num: null;
	@each $key,
	$num in $map {
		@if $prev-num==null {
			// Do nothing
		}
		@else if not comparable($prev-num, $num) {
			@warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}
		@else if $prev-num>=$num {
			@warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}
		$prev-key: $key;
		$prev-num: $num;
	}
}

// Starts at zero
// Another grid mixin that ensures the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map) {
	$values: map-values($map);
	$first-value: nth($values, 1);
	@if $first-value !=0 {
		@warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
	}
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");
// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.
@if $enable-grid-classes {
	.container {
		@include make-container();
		@include make-container-max-widths();
	}
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.
@if $enable-grid-classes {
	.container-fluid {
		@include make-container();
	}
}

// Row
//
// Rows contain and clear the floats of your columns.
@if $enable-grid-classes {
	.row {
		@include make-row();
	} // Remove the negative margin from default .row, then the horizontal padding
	// from all immediate children columns (to prevent runaway style inheritance).
	.no-gutters {
		margin-right: 0;
		margin-left: 0;
		>.col,
		>[class*="col-"] {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

// Columns
//
// Common styles for small and large grid columns
@if $enable-grid-classes {
	@include make-grid-columns();
}

//----------  Responsive visibility utilities
//------------------------------------------------------------------------------
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.d#{$infix}-none         { display: none !important; }
		.d#{$infix}-inline       { display: inline !important; }
		.d#{$infix}-inline-block { display: inline-block !important; }
		.d#{$infix}-block        { display: block !important; }
		.d#{$infix}-table        { display: table !important; }
		.d#{$infix}-table-row    { display: table-row !important; }
		.d#{$infix}-table-cell   { display: table-cell !important; }
		.d#{$infix}-flex         { display: flex !important; }
		.d#{$infix}-inline-flex  { display: inline-flex !important; }
	}
}


// stylelint-disable declaration-no-important

// Flex variation
//
// Custom styles for additional flex alignment options.

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.flex#{$infix}-row            { flex-direction: row !important; }
		.flex#{$infix}-column         { flex-direction: column !important; }
		.flex#{$infix}-row-reverse    { flex-direction: row-reverse !important; }
		.flex#{$infix}-column-reverse { flex-direction: column-reverse !important; }

		.flex#{$infix}-wrap         { flex-wrap: wrap !important; }
		.flex#{$infix}-nowrap       { flex-wrap: nowrap !important; }
		.flex#{$infix}-wrap-reverse { flex-wrap: wrap-reverse !important; }
		.flex#{$infix}-fill         { flex: 1 1 auto !important; }
		.flex#{$infix}-grow-0       { flex-grow: 0 !important; }
		.flex#{$infix}-grow-1       { flex-grow: 1 !important; }
		.flex#{$infix}-shrink-0     { flex-shrink: 0 !important; }
		.flex#{$infix}-shrink-1     { flex-shrink: 1 !important; }

		.justify-content#{$infix}-start   { justify-content: flex-start !important; }
		.justify-content#{$infix}-end     { justify-content: flex-end !important; }
		.justify-content#{$infix}-center  { justify-content: center !important; }
		.justify-content#{$infix}-between { justify-content: space-between !important; }
		.justify-content#{$infix}-around  { justify-content: space-around !important; }

		.align-items#{$infix}-start    { align-items: flex-start !important; }
		.align-items#{$infix}-end      { align-items: flex-end !important; }
		.align-items#{$infix}-center   { align-items: center !important; }
		.align-items#{$infix}-baseline { align-items: baseline !important; }
		.align-items#{$infix}-stretch  { align-items: stretch !important; }

		.align-content#{$infix}-start   { align-content: flex-start !important; }
		.align-content#{$infix}-end     { align-content: flex-end !important; }
		.align-content#{$infix}-center  { align-content: center !important; }
		.align-content#{$infix}-between { align-content: space-between !important; }
		.align-content#{$infix}-around  { align-content: space-around !important; }
		.align-content#{$infix}-stretch { align-content: stretch !important; }

		.align-self#{$infix}-auto     { align-self: auto !important; }
		.align-self#{$infix}-start    { align-self: flex-start !important; }
		.align-self#{$infix}-end      { align-self: flex-end !important; }
		.align-self#{$infix}-center   { align-self: center !important; }
		.align-self#{$infix}-baseline { align-self: baseline !important; }
		.align-self#{$infix}-stretch  { align-self: stretch !important; }
	}
}
//----------  layout
//------------------------------------------------------------------------------

body,
html {
	height: 100%;
}

body {
	font-family: $font-default;
	font-size: 14px;
	display: grid;
	grid-template-rows: 88px 1fr 80px;
	grid-template-columns: 1fr;
	@include breakpoint(phone) {
		grid-template-rows: 88px 1fr 60px;
	}
}

.wrap {
	@extend %clearfix;
	margin: 0 auto;
	max-width: 1000px;
	padding: 0 20px;
	width: 100%;
}
//----------  footer
//------------------------------------------------------------------------------

.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	padding: 0 45px;
	border-top: 1px solid $grey;
	ul {
		display: flex;
		li {
			img {
				height: 19px;
				margin-left: 35px;
			}
			a {
				&:hover {
					opacity: 0.8;
				}
			}
		}
	}
	p {
		color: $black;
		font-size: 14px;
		opacity: 0.5;
	}
	a {
		color: $black;
		font-size: 14px;
		&:hover {
			text-decoration: underline;
		}
	}
	@include breakpoint(phone) {
		padding: 0 17px;
		height: 60px;
		p, a {
			font-size: 11px;
		}
		ul {
			display: none;
		}
	}
}
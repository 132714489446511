//----------  header
//------------------------------------------------------------------------------

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 45px;
	height: 88px;
	img {
		height: 32px;
	}
	.right {
		img {
			width: 21px;
		}
		a {
			opacity: 0.8;
			margin-left: 20px;
			span {
				font-size: 14px;
				line-height: 21px;
				color: $black;
			}
			&:hover {
				opacity: 0.8;
			}
		}
	}
	@include breakpoint(phone) {
		padding: 0 25px;
		.right {
			a {
				span {
					display: none;
				}
			}
		}
	}
}
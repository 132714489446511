//----------  typography
//------------------------------------------------------------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $font-default;
	font-weight: normal;
	line-height: 1.35em;
}

h1, .h1 { font-size: 48px; }

h2, .h2 { font-size: 36px; }

h3, .h3 {
	font-family: $font-bold;
	font-size: 18px;
	line-height: 27px;
	color: $black;
}

h4, .h4 { font-size: 24px; }

h5, .h5 { font-size: 20px; }

h6, .h6 { font-size: 16px; }

// Icon font
.icon {
	line-height: 1;
	display: inline-block;
}

//----------  fonts
//------------------------------------------------------------------------------

@font-face {
    font-style: normal;
    font-family: 'ClanPro-Bold';
    src: url('../../fonts/ClanPro-Bold.eot');
    src: url('../../fonts/ClanPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/ClanPro-Bold.woff') format('woff'),
         url('../../fonts/ClanPro-Bold.ttf') format('truetype'),
         url('../../fonts/ClanPro-Bold.svg#ClanPro-Bold') format('svg');
}

@font-face {
    font-style: normal;
    font-family: 'ClanPro-Book';
    src: url('../../fonts/ClanPro-Book.eot');
    src: url('../../fonts/ClanPro-Book.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/ClanPro-Book.woff') format('woff'),
         url('../../fonts/ClanPro-Book.ttf') format('truetype'),
         url('../../fonts/ClanPro-Book.svg#ClanPro-Book') format('svg');
}

@font-face {
    font-style: normal;
    font-family: 'ClanPro-Book';
    src: url('../../fonts/ClanPro-Medium.eot');
    src: url('../../fonts/ClanPro-Medium.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/ClanPro-Medium.woff') format('woff'),
         url('../../fonts/ClanPro-Medium.ttf') format('truetype'),
         url('../../fonts/ClanPro-Medium.svg#ClanPro-Medium') format('svg');
}